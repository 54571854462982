import React from 'react';
import { ProcessedStore } from './StoreSection';
import CostRow from './costRow';
import SummaryTable from './SummaryTable';

const SingleStoreTable = (props: {
  store: ProcessedStore;
  display: boolean;
  ordersCount?: number;
}) => {
  const { store } = props;
  if (!props.display) {
    return null;
  }
  return (
    <div key={store.storeId}>
      <h3 style={{ textAlign: 'center' }}>{store.storeName}</h3>
      <SummaryTable
        {...{
          totalCost: store.totals.totalCost || 0,
          totalRevenue: store.totals.totalRevenue,
          totalItems: store.totals.itemsSold,
          totalPackages: props.ordersCount || 0,
        }}
      />
      <table>
        <thead>
          <tr>
            <th>SKU</th>
            <th>Items Sold</th>
            <th>In Orders</th>
            <th>Total Revenue</th>
            <th>Cost Per Item</th>
            <th>Total Cost</th>
            <th>Profit</th>
          </tr>
        </thead>
        <tbody>
          {store.data.map((item) => {
            return (
              <CostRow
                key={item.sku}
                item={item}
                totalCost={item.totalCost || 0}
              />
            );
          })}
          <CostRow item={store.totals} totalCost={0} />
        </tbody>
      </table>
    </div>
  );
};

export default SingleStoreTable;
