import { queryDate } from '../../../types/Store';
const Year = (props: { state: queryDate; stateSet: any; initial: any }) => {
  const fillYear = () => {
    const options = [];
    const year = new Date(Date.now()).getFullYear();
    let yearWhile = year - 2;
    while (yearWhile <= year) {
      options.push(
        <option value={yearWhile} key={`y${yearWhile}`}>
          {yearWhile}
        </option>
      );
      yearWhile++;
    }
    return options;
  };

  const onChange = (e: any) => {
    const oldState = { ...props.state };
    oldState.day = e.target.value;
    props.stateSet(oldState);
  };

  return (
    <div className="select-container">
      <select onChange={onChange} defaultValue={props.initial}>
        {fillYear()}
      </select>
    </div>
  );
};

export default Year;
